.trl-norealtime-icon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center; /* Safari iOS 14, see SBAHNMW-338 */
  flex-shrink: 0;
  flex-grow: 0;

  svg {
    width: 100%;
  }
}
