#warning-info {
  background-color: white;
  display: flex;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin: 5px 0;

  .close-button {
    height: 50px;
    width: 50px;
  }

  #warning-icon {
    background-color: #e01e1e;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }

  #warning-text {
    flex: 2;
  }
}

@media (width <= 400px) {
  #warning-info {
    font-size: 80%;
  }

  #warning-icon {
    width: 50px;
  }
}
