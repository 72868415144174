body,
html,
#root {
  height: 100%;
}

#root #app {
  position: relative;
  height: 100%;
  background-color: #e3e1e0;
}

#map-controls {
  left: 20px;
}

div[role="button"] {
  cursor: pointer;
}

#debug #map {
  width: 50%;
  position: absolute;

  // The user of videowall paramaters seems to use a browser that doesn't support inset css
  // see SBAHNMW-835
  // inset: 0 0 0 50%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
}

.bt-reset {
  text-align: left;
  font-family: inherit;
  font-size: inherit;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.rt-route-icon .pulse {
  animation: blink 1s infinite ease-in-out;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.tm-w-xs,
.tm-w-s,
.tm-w-m,
.tm-w-l {
  .trl-bookmark-add .tm-button {
    width: 35px;
    min-width: 35px;

    & > span:last-child {
      display: none;
    }
  }
}

.tm-w-xs,
.tm-w-s,
.tm-h-xs,
.tm-h-s {
  #warning-info {
    display: none;
  }

  #route-info {
    .head {
      .time {
        width: 36px;
        min-width: 36px;
      }
    }
  }

  .rt-route-schedule {
    .rt-route-body {
      .rt-route-station {
        .rt-route-times {
          width: 30px;
          min-width: 30px;
        }
      }
    }
  }
}

.tm-h-l,
.tm-h-xl {
  .trl-bookmarks-popup {
    height: auto;
  }
}

.tm-w-xl {
  #navigator {
    &.controls-hidden {
      .copyright, #map-controls {
        opacity: 1;
      }
    }
  }

  #vehicles-list {
    padding: 5px;
    display: flex;
    align-items: center;
    border-top: none;
    flex-wrap: wrap;
    width: calc(100% - 10px); // avoid display of the x scrollbar

    > .route-header {
      margin: 15px 10px 0;
      min-width: 320px;
      max-width: 450px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
      background: white;
    }

    > .route-header:nth-child(even) {
      background: white;
    }
  }
}

.abc {
  width: 25px;
}

.tm-dialog-container {
  box-shadow: 0 0 20px #00000029;
  border-radius: 5px;
  background: #fff;
  width: auto;
  height: auto;
  padding: 0;
  margin: auto;
  overflow: hidden;
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);

  .tm-dialog-header {
    min-height: 60px;
    display: flex;
    align-items: center;
    position: relative;

    .trl-header-icon {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 24px;
      }
    }

    .trl-header-title,
    .trl-header-subtitle {
      font-weight: 600;
    }

    .trl-header-title {
      font-size: 23px;
      line-height: 27.6px;
    }

    .trl-header-subtitle {
      font-size: 16px;
      line-height: 19.2px;
      margin-top: 6px;
    }

    .tm-dialog-close-bt {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tm-dialog-body {
    padding: 20px;
  }

  .trl-popup-station-icon {
    width: 30px;
    min-width: 25px;
    display: flex;
    align-items: center;
  }

  .trl-popup-station-label {
    display: inline-block;
    flex: 1;
    margin-left: 20px;
    text-align: left;
    font-weight: 600;
  }
}

input[type="text"] {
  background-color: #efefef;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  border: 1px solid;

  &:focus {
    border: 2px solid #88a9eb;
  }
}

.trl-warning {
  color: red;
}
