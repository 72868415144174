#map-controls {
  position: fixed;
  top: 20px;
  transition: all 0.2s ease-in;
}

#map-controls .map-control {
  border-radius: 50%;
  background-color: #f3f3f3;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 50%);
  cursor: pointer;
  border: 1px solid #dadada;
  padding: 5px;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

#map-controls .map-control img {
  width: 100%;
}

#map-controls .map-control:hover {
  box-shadow: 2px 3px 3px rgb(0 0 0 / 50%);
}

@media (width <= 800px) {
  #map-controls .map-control {
    height: 30px;
    width: 30px;
    margin-bottom: 15px;
  }
}
