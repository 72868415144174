.station-icons-legend {
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    > div:first-child {
      width: 30px;
      min-width: 25px;
      display: flex;
      align-items: center;
    }

    > div:last-child {
      flex: 1;
      margin-left: 20px;
      text-align: left;
      font-weight: 600;
    }
  }
}
