.notification {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 5px 0;

  .close-button {
    margin-top: 8px;
  }

  .notification-icon {
    width: 64px;
    height: 100%;
    display: flex;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .notification-info {
    flex-grow: 1;
  }

  .notification-start-end {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .notification-title {
    margin-bottom: 5px;
  }

  .notification-products {
    display: flex;
    flex-wrap: wrap;

    .line-icon-svg {
      margin: 0 5px 5px 0;
    }
  }
}

.notification-content {
  margin: 0 15px;

  .notification-content-metadata {
    font-weight: bold;
  }

  .notification-content-description {
    img {
      max-width: 100%;
    }

    p {
      margin: 0.8rem 0;
    }
  }

  .notification-content-rail-replacement {
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      height: 30px;
      margin: 0 25px 0 10px;
    }
  }

  .notification-link-list-header {
    margin-bottom: 0;
  }

  .notification-link-list {
    list-style-type: none;
    padding: 0;
  }
}
