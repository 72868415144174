


/* On bigscreen overlay on the right */
.trl-overlay {
  width: 0;
  position:relative;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 0;
  overflow-y:auto;
}    


.trl-overlay-scrollable-handler {
  background-color: white;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  touch-action: none;
  display: flex;
  justify-content: center;
  z-index: 3000;
}

.trl-overlay-handler-zone {
  position: absolute;
  top: -5px;
  left: 0;
  width: calc(100% - 60px);
  height: 50px;
  background-color: "transparent";
}

.tm-w-m,
.tm-w-l {
  .route-info-active {
    .trl-overlay-handler-zone {
      width: calc(100% - 190px);
    }
  }
}

.tm-w-xl {
  .bottom-container-active {
    .trl-overlay {
      width:610px; 
      min-height:75px;
      max-height: 100%;
      height:100%!important;
      box-shadow: 0 -2px 10px rgb(0 0 0 / 20%);      
    }

    .trl-overlay-scrollable-handler {
      display: none;
    }
  }
}

/* By default overlay at the bottom */
.tm-w-l,
.tm-w-m,
.tm-w-s,
.tm-w-xs {
  .bottom-container-active {
    .trl-overlay {
      width: 100%;
      min-height:75px;
      max-height: 70%;
      box-shadow: 0 -2px 10px rgb(0 0 0 / 20%);
  
    }
  }
}