#platform-info {
  background-color: white;
  display: flex;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin: 5px 0;
  justify-content: space-between;
}

#platform-info a {
  text-decoration: none;
  color: initial;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#platform-info .underline {
  border-bottom: 1px dashed #aaa;
  cursor: pointer;
}

#platform-info .close-button {
  display: none;
  height: 50px;
}

.tm-w-xl #platform-info .close-button {
  display: flex;
}

@media (width <= 400px) {
  #platform-info {
    font-size: 80%;
  }
}
