.rt-route-icon {
  width: 50px;
  min-width: 50px;
  min-height: 46px;
  position: relative;

  .rt-rect-middle,
  .rt-circle-middle,
  .rt-rect-vertical {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }

  .rt-rect-middle {
    top: 0;
    bottom: 0;
    width: 25px;
    height: 8px;
  }

  .rt-circle-middle {
    top: 16px;
    height: 7px;
    width: 7px;
    border-radius: 7px;
    border: 4px solid;
    background-color: white;
    opacity: 1;
  }

  .rt-rect-vertical {
    width: 5px;
    height: 100%;
  }

  .rt-rect-vertical-notification {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath style='fill:%23eaa1aa' d='M0 0h40v40H0z'/%3E%3Cpath style='fill:%23fc0009' d='M12 0h16v25.045H12z'/%3E%3C/svg%3E");
    width: 15px;
    top: 50%;

    &.rt-rect-vertical-notification-disruption-rail-replacement {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath style='fill:%23c688be' d='M0 0h40v40H0z'/%3E%3Cpath style='fill:%238400a8' d='M12 0h16v25.045H12z'/%3E%3C/svg%3E");
    }

    &.rt-rect-vertical-notification-deviation {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath style='fill:%23eaa1aa' d='M0 0h40v40H0z'/%3E%3Cpath style='fill:%23ffffff' d='M12 0h16v40H12z'/%3E%3Cpath style='fill:%23c9c9c9' d='M12 0h16v25.045H12z'/%3E%3C/svg%3E");
    }

    &.rt-rect-vertical-notification-deviation-stops {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2'%3E%3Cpath style='fill:%23ffffff' d='M12 0h16v40H12z'/%3E%3Cpath style='fill:%23c9c9c9' d='M12 0h16v25.045H12z'/%3E%3C/svg%3E");
    }

    &.rt-rect-vertical-notification-end:not(.rt-rect-vertical-notification-start) {
      background: none;
    }

    .notification-icon {
      height: 30px;
      position: absolute;
      left: -58px;
      top: 8px;
    }
  }

  .rt-route-notification-icon {
    position: absolute;
    height: 31px;
    top: 30px;
    left: 8px;
    z-index: 2;
  }
}

.rt-route-has-notification {
  position: relative;

  .rt-route-icon {
    height: 100%;
    position: absolute;
    left: 66px;
  }
}
