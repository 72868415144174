@import "../globals";

#vehicles-list {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  border-top: 3px solid #e2e1e0;

  > div:nth-child(even) {
    background: $even-color;
  }

  > div:nth-child(odd) {
    background: $odd-color;
  }
}
