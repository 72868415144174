#station-name {
  height: 50px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgb(0 0 0 / 12%);

  .zob-icon:not(svg) {
    width: 100%;
    height: 100%;
  }

  .name {
    flex-grow: 4;
    display: flex;
    align-items: center;
  }

  img {
    height: 24px;
  }
}

