@import "./node_modules/react-spatial/themes/default/variables";

$even-color: #f0f0f0;
$odd-color: white;
$btn-size-base: 50px;

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(97 97 97);
  z-index: 5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(61 61 61);
}
