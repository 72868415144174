.line-icon-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;

  & svg {
    width: 100%;
    height: auto;
  }
}
