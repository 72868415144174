.trl-popup {
  box-shadow: 0 0 20px #00000029;
  border-radius: 5px;
  background: #fff;
  width: 280px;
  height: auto;
  padding: 34px;
  margin: auto;
  text-align: center;

  .trl-popup-title {
    display: flex;
    margin-bottom: 26px;

    .trl-norealtime-icon {
      width: 42px;
      height: 42px;
      margin-right: 15px;
    }

    span {
      font-weight: 700;
      text-align: left;
    }
  }

  .trl-popup-close-btn {
    display: none;
  }

  .trl-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 24px;

    .trl-popup-green {
      color: green;
    }

    .trl-popup-ok-btn {
      text-align: center;
      padding: 8px;
      width: 70px;
      border-radius: 4px;
      font-weight: bold;
      margin-top: 26px;
      background-color: #efefef;

      &:hover {
        background-color: #ebe8e8;
      }
    }
  }
}
