@import "../globals";

.rt-route-station {
  height: 46px;
  border-radius: 0;

  .rt-route-stop-name {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }

  .rt-route-wing-stop-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rt-route-times {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 0;
    text-align: center;

    .rt-route-time-departure {
      display: none;
    }
  }

  &.rt-route-stop-cancelled {
    color: inherit;

    .rt-route-times {
      visibility: hidden;
    }

    .rt-route-stop-name {
      text-decoration: line-through red;
    }
  }

  &.rt-route-next-stop {
    font-weight: bold;
  }
}
