.route-header {
  min-height: 50px;
  border-bottom: 3px solid;
  display: flex;
  background: white;
  padding: 0;
  align-items: center;

  svg {
    width: 100%;
  }

  .line-icon-svg svg {
    height: auto;
    max-height: 35px; // For sbahn icon
  }

  .line-icon,
  .line-icon-svg {
    margin: 0 10px;
  }

  .destinations {
    overflow: hidden;
    flex-wrap: wrap;
    align-items: center;
    flex: 2;
    padding: 10px 0;
  }

  .real-destination,
  .cancelled-destination,
  .other-destination {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .destination {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .real-destination {
    margin-right: 15px;
    display: flex;
    align-items: center;
    font-size: 23px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .trl-norealtime-icon {
      width: 25px;
      height: 100%;
      margin-left: 10px;
    }
  }

  .cancelled-destination {
    font-size: 0.8em;
    text-decoration: line-through red;
  }

  .other-destination {
    font-size: 16px; /* like othe destination in RouteInfo */
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 63px;

    .line-icon-svg {
      margin: 0 5px 0 0;
      width: 36px;
      min-width: 36px;
    }

    .other-destination-sign {
      height: 20px;
      width: 25px;
      margin: 5px 5px 5px 2px;
    }
  }

  .route-header-buttons {
    display: flex;
    align-items: flex-start;
    margin: 2px 0;
  }

  .route-follow {
    width: auto;
    border-radius: 5px;
    min-width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    height: 30px;
    
    span {
      padding-left: 7px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .close-button {
    height: 30px;
    padding: 0;
  }

  .rt-active {
    svg {
      path,
      circle {
        fill: inherit;
      }
    }
  }
}

.tm-w-xs,
.tm-w-s {
  .route-follow {
    justify-content: center;
    min-width: 35px;

    span {
      display: none;
    }
  }

  .close-button {
    min-width: 40px;
    max-width: 40px;
    width: 40px;

    svg {
      width: 40px;
    }
  }
}
