.websocket-info {
  &.backdrop-error-message {
    position: absolute;
    background: rgb(0 0 0 / 30%);
    width: calc(100% + 5px);
    height: 1000%;
    top: 0;
    z-index: 10000;
    margin: 0 -10px -5px -5px;
  }

  .error-message {
    width: 80%;
    margin: auto;
    position: sticky;
    top: 100px;
    margin-top: 50px;

    svg {
      width: 100%;
      height: 62%;

      & path:last-child {
        color: rgb(236 0 22);
      }
    }
  }
}
