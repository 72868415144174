.trl-elevator-status-legend {
  .trl-elevator-info {
    display: grid;
    grid-template-columns: 10% 90%;
    align-items: flex-start;

    ul {
      padding-left: 4px;
      margin: 2px 0;
      line-height: 22.4px;
      list-style: none;

      li {
        font-weight: 400;
        padding-left: 20px;
      }

      li::before {
        content: "•";
        font-size: 18px;
        padding-right: 10px;
      }
    }

    .trl-popup-station-label {
      margin-top: 5px;
    }
  }
}
