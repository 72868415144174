#navigator {
  position: absolute;

  // The user of videowall paramters seems to use a browser that doesn't support inset css
  // see SBAHNMW-835
  // inset: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .tm-modal {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 10%);
    z-index: 9999;
    display: flex !important;
  }

  .trl-main-flex-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden auto;

    & > div:first-child {
      position: relative;
      flex:1;
      z-index: 10,
    }

    & > div:last-child {
      position: relative;
      z-index: 20;
    }
  }

  .trl-bottom-links {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    font-size: .8rem;
    align-items: flex-end;
    flex-direction:column;
    text-align: right;
    pointer-events: none; /* allow click on map */

    a {
      color: initial;
      text-decoration: none;
      pointer-events: all; /* allow click on map */
    }
  }

  #map-controls,
  .trl-bottom-links {
    opacity: 1;
    transition: opacity .3s ease;
  }

  &.controls-hidden { 
    #map-controls,
    .trl-bottom-links {
      opacity: 0;
    }
  }
}

#station-name .name {
  font-size:23px;
}

.marker,
.close-button {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex: 1 1;
}

.marker {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
}

.close-button {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.close-button svg {
  width: 100%;
  height: 20px;
}


.tm-w-xs,
.tm-w-s {
  #station-name .name,
  .destinations {
    font-size: 18px;
  }

  .marker {
    min-width: 30px !important;
    height: 30px;
  }

  .close-button {
    position: relative;
    right: 0;
  }

  .close-button svg {
    width: 20px;
    height: 20px;
  }
}

.tm-w-xl {
  #navigator {
    &.videowall {
      &.map-under-bottom-container {
        #bottom-container {
          position:absolute;
          top: 0;
          right: 0;
          box-shadow:  none;
          padding: 5px;
          width: 320px;
        }
      }

      #disruption-info .block {
        box-shadow: unset;
        border-radius: 5px;
        border: 1px lightgray solid;
      }
    }

    .trl-main-flex-container {
      flex-direction:row;
    }

    .trl-bottom-links {
      align-items: start;
      justify-content: space-between;
      flex-direction:row-reverse;

      .copyright {
        text-align: left;

      }
    }
  }
}
