.trl-bookmark-add {
  display: flex;
  flex-direction: column;
  padding: 10px 22px;

  .trl-inputs {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    height: 35px;

    > input {
      flex: 2;
      padding-left: 15px;

      &:focus {
        padding-left: 14px;
      }
    }
  }

  .tm-button {
    display: flex;
    width: 150px;
    min-width: 150px;
    flex: 0;
    border-radius: 5px;
    background-color: #f61515;
    color: white;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    &:hover {
      opacity: 0.8;
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        opacity: 0.5;
      }
    }

    > span:first-child {
      display: flex;
      align-items: center;
      margin: 0 15px;
    }

    > span:last-child {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }
}
