.trl-icon-button {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

@media (width <= 600px) {
  .trl-icon-button {
    width: 20px;
    height: 20px;
  }
}
