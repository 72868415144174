#map {
  position: absolute;
  height: 100%;
  width: 100%;

  .ol-overlaycontainer,
  .ol-overlaycontainer-stopevent {
    pointer-events: none;
  }

  .mapboxgl-canvas {
    position: absolute;
  }
}
