.trl-mots-relation {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  height: auto;
  min-height: 72px;
  // max-height: 100px;
  background: #ebebeb;
  padding: 10px;
  align-items: center;
  overflow: hidden;
  justify-content: left;
  flex-wrap: wrap;
  padding-left: 0;
  padding-bottom: 0;
  z-index: 100; //Over bottom-container

  .trl-icon {
    margin: 0 20px;

    svg {
      height: 45px;
      width: 100%;
      fill: #00000029;
    }
  }

  .trl-station {
    display: flex;
    align-items: center;
    min-width: 290px;
    max-width: 490px;
    margin-right: 20px;
    margin-bottom: 10px;

    .trl-station-name {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .trl-mots-container {
    display: flex;
    flex: 1 1;
    margin-bottom: 10px;
  }

  .line-icon-svg {
    width: 50px;
    min-width: 50px;
  }

  .trl-mot {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    height: auto;
    min-height: 72px;
    max-height: 72px;
    overflow: hidden auto;
    align-items: center;

    > span {
      width: 60px;
      height: 24px;
      display: flex;
      justify-content: center;
    }

    img {
      margin: 0 10px;
    }
  }

  .trl-mot-regionalbahn,
  .trl-mot-fernverkehr,
  .trl-mot-faehre {
    max-width: 32px;
    min-width: 32px;
  }
}

.tm-w-xs {
  .trl-mots-relation {
    flex-direction: row;
  }

  .trl-station {
    margin-bottom: 0;
  }

  .trl-station,
  .trl-mots-container {
    width: 100%;
  }

  .trl-mots-container {
    margin-left: 50px;
  }

  .trl-mot {
    height: auto;
    min-height: 0;
  }
}

.tm-w-s,
.tm-w-xs {
  .trl-icon {
    min-width: 25px;
  }

  .trl-station .trl-station-name {
    font-size: 20px;
  }

  .trl-mots-relation {
    .trl-mot-s-bahn,
    .trl-mot-u-bahn {
      max-width: 125px;
      min-width: 60px;
    }
  }
}
