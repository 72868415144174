@import "../globals";

.other-destination-sign {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  overflow: hidden;

  .main-route {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: black;
  }
}
