.list {
  clear: right;
}

/**
 * Keep height of parent from floating elements:
 * https://stackoverflow.com/questions/218760/how-do-you-keep-parents-of-floated-elements-from-collapsing
 */

.head .col {
  float: left;
  z-index: 100;
}

#station-name {
  z-index: 1001;
  position: sticky;
  top: 0;
}

#info-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  line-height: 21px;
  opacity: 1;
  padding: 7px 2px 7px 10px;
  height: 50px;
  max-height: 50px;
}

#departures {
  background-color: #efefef;
  transition: opacity 0.2s ease-out 0.2s;
  opacity: 1;
  position: relative;
  margin-bottom: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);

  &.out {
    opacity: 0;
  }

  .list {
    border-bottom: 1px solid rgb(0 0 0 / 12%);
  }

  .head {
    height: 20px;
    position: sticky;
    top: 50px;
    box-shadow: 0 1px 15px rgb(0 0 0 / 20%);
  }

  button.item {
    border: none;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    text-align: left;

    .col {
      float: left;
      z-index: 100;
    }

    &.stale {
      max-height: 0;
      overflow: hidden;
    }

    &.selected {
      background-color: #cacaca;
    }

    &.ris {
      position: relative;
    }
  }

  .debug {
    clear: both;
    padding: 10px 0 0 10px;
    color: grey;
    font-size: 12px;
  }

  .state-circle {
    margin: 3px 3px 0 0;
    float: left;
    height: 14px;
    width: 14px;
    border-radius: 50%;
  }

  .direction {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    max-height: 40px;
    margin: 5px 0;
    overflow: hidden;
    opacity: 1;

    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }

  .list .item,
  .head {
    background-color: white;
    line-height: 21px;
    opacity: 1;
    padding: 7px 10px;
  }

  .list .item {
    background: white;
    max-height: 150px;
    padding: 0;
    transition: max-height 1s ease-out 2s;
    cursor: pointer;

    &.no-route-info {
      cursor: auto;
    }

    .item-inner {
      padding: 12px 10px;
    }

    &:nth-child(even) {
      background-color: #f0f0f0;
    }

    &:nth-child(odd) {
      background-color: white;
    }

    &::after {
      content: " ";
      display: block;
      height: 0;
      clear: both;
    }
  }

  .head,
  .list .item .item-inner {
    padding-left: 0;
  }

  .head.departures {
    z-index: 1000;
  }

  .body.list {
    z-index: 0;
  }
}

.line.col {
  clear: both;
  width: 64px;
  text-align: center;

  .line-icon-svg {
    padding: 0 10px;
    width: 44px;
    min-width: 44px;
  }
}

.destination.col {
  // sum of other col width + padding-right + 2px for safety
  width: calc(100% - 236px);
  display: flex;
  align-items: center;
}

.platform.col {
  text-align: right;
  width: 35px;
}

.part.col {
  width: 90px;
  height: 17px;
  display: flex;
  justify-content: flex-end;
}

.time.col {
  text-align: right;
  width: 45px;
}

@media (width <= 400px) {
  #departures .head.departures {
    font-size: 80%;
  }
}

@media (width <= 800px) {
  .destination.col {
    width: calc(100% - 206px);
    text-overflow: ellipsis;
    overflow: hidden;

    > span:first-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .part.col {
    width: 60px;
  }

  #departures .item {
    img.abc {
      width: 15px;
    }

    .abc.unknown {
      width: 45px;
    }
  }
}

@media (width <= 1100px) {
  #departures .item img.abc {
    width: 15px;

    &.unknown {
      width: 45px;
    }
  }
}
