#departures .item .door-open {
  font-size: 20px !important;
}

.train {
  width: 25px;
  height: 25px;
}

.train-arrival {
  width: 18px;
  height: 18px;
}

.time {
  width: 18px;
  height: 18px;
}
