.tm-dialog-container.trl-legend-popup {
  width: 375px;
  min-height: 258px;

  .tm-dialog-body {
    padding: 10px 20px 30px;
  }

  .trl-legend-popup-header {
    padding: 20px 20px 10px;
    margin-top: 6px;

    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .trl-elevator-title {
      display: block;
    }

    .trl-header-icon {
      width: 22px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .trl-legend-popup-footer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .trl-popup-ok-btn {
    text-align: center;
    padding: 8px;
    width: auto;
    border-radius: 4px;
    font-weight: bold;
    margin-top: 26px;
    background-color: #efefef;
    flex: 0 0;

    &:hover {
      background-color: #ebe8e8;
    }
  }
}
