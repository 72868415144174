body {
  margin: 0;
  padding: 0;
}

body,
input {
  font-family: myriad-pro, sans-serif;
  font-size: 16px;
}
