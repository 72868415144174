#disruption-info .block {
  display: flex;
  font-size: 12px;
  margin: 0 0 5px;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  line-height: 150%;

  .line-icon,
  .line-icon-svg {
    height: 25px; // IE 11 --> SBAHNMW-321
    width: 44px;
    min-width: 44px;
  }

  .image-block {
    min-width: 64px;
    max-width: 64px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 17px 0;
  }

  .info-block {
    min-width: 0; /* without this the text doesn't fit the width propertly */overflow: hidden;
    word-wrap: break-word;
    padding: 17px 10px 17px 0;
  }

  h2.title {
    font-size: 14px;
    margin: 0 0 7px;
  }

  .connections {
    margin: 10px 0;
  }

  .close-button {
    margin-left: auto;
    height: 50px;
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    box-sizing: border-box;
  }
}

.strong {
  font-weight: 600;
}
