.trl-bookmark {
  width: 100%;
  padding: 15px 0;
  display: flex;
  align-items: center;

  &.trl-editing {
    padding: 8px 0;
  }

  .trl-bookmark-open {
    min-width: 60px;
    display: flex;
    justify-content: center;
  }

  .trl-bookmark-title {
    flex: 1;
    flex-direction: column;
    display: flex;

    input {
      flex: 1;
      margin-left: -7px;
    }
  }

  .trl-bookmark-buttons {
    display: flex;
    align-items: center;
    margin-right: 12px;

    div {
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        path {
          fill: black;
        }
      }
    }
  }
}
