.error-message {
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}

.error-icon {
  min-width: 64px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.no-time:nth-child(even) {
  margin-top: 2px;
}

@media (width <= 400px) {
  .error-message {
    font-size: 80%;
  }
}
