.diversity-header {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  align-items: center;

  img {
    width: 142%;
  }
}
