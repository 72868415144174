@import "../globals";
@import "../../node_modules/react-spatial/components/RouteSchedule/RouteSchedule";

#route-info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fefefe;
  margin-bottom: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  

  .head,
  .rt-route-body,
  .route-header,
  .notification-list-container,
  .diversity-header {
    z-index: 1; //Needed for sticky elements on top of others
  }

  .notification-list-container,
  .diversity-header {
    background-color: white;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }

  .rt-route-body {
    margin-bottom: 0;
  }

  .head,
  .rt-route-body {
    padding: 7px;
  }

  .rt-route-schedule {
    width: 100%;
    overflow: visible;
  }

  .rt-route-header,
  .rt-route-body {
    padding: 0;
  }

  .route-header {
    width: 100%;
    position: sticky;
    top: 0;
  }

  .rt-route-schedule .rt-route-body {
    font-size: inherit;

    .rt-route-station {
      width: 100%;
      background-color: inherit;
      color: inherit;
      border-radius: 0;

      &:hover {
        color: inherit;
        background-color: inherit;
      }

      &:nth-child(even) {
        background: $even-color;
      }

      &:nth-child(odd) {
        background: $odd-color;
      }
    }
  }

  .route-info-sticky {
    position: sticky;
    top: 57px;
    z-index: 1;
  }

  .head {
    height: 20px;
    padding: 7px 0;
    background-color: #fefefe;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 15px rgb(0 0 0 / 20%);

    .time {
      margin-left: 20px;
      width: 46px;
      min-width: 46px;
      text-align: center;
    }

    .progress {
      width: 50px;
      min-width: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 70%;
        width: 70%;
      }
    }
  }

  &.route-info-multiple-destination-2 {
    .route-info-sticky {
      top: 82px;
    }
  }
}
