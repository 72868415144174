#bookmarks-button {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 35px;
  }
}

#map #bookmarks-button.active {
  background-color: #a9a9a9;
}
