.trl-bookmarks-popup {
  width: 600px;
  height: 100%;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  overflow: visible; /* for box-sahdow of child */

  & > div {
    background: white;
  }

  & > div:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  & > div:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .tm-dialog-body {
    padding: 0 0 20px;
    max-height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
  }

  .trl-bookmarks-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
    flex: 0;

    > div {
      text-align: center;
    }

    .trl-bookmarks-info-title,
    .trl-bookmarks-info-title2 {
      font-weight: bold;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 10px;
      }
    }

    .trl-bookmarks-info-title {
      font-size: 23px;
    }
  }

  .trl-bookmark-add {
    flex: 0 0;
  }

  .trl-bookmarks {
    flex: 2 1;
  }
}
