.line-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid;
  width: 41px;
  min-width: 41px;
  line-height: 19px;
  padding: 0;
  font-family: sans-serif;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
