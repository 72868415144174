.station-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  margin-left: 10px;
  gap: 4px;

  div {
    display: flex;
    align-items: center;
    width: 30px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .trl-icon-zob {
    width: 82px;
  }
}

@media (width <= 600px) {
  .trl-icon-zob {
    width: 70px !important;
  }
}
